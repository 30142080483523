<template>
  <div>
    <h2 class="h4">Send out announcement</h2>
    <div class="card">
      <div class="card-body">
        <div class="alert alert-info">
          <p class="mb-0">Send an email to all members of a selected group or to a specisic email.</p>
        </div>
        <form @submit.prevent="submit">
          <div class="form-row">
            <div class="col-md-3">
              <div class="form-group">
                <label>Receipient(s)</label>
                <select class="form-control" v-model="selected_receipient">
                  <option value="0">Select Group</option>
                  <option value="1">Specific Email</option>
                </select>
              </div>
            </div>
            <div class="col-md-9">
              <div class="form-group" v-if="selected_receipient == 1">
                <label>Email <small>comma separated for multiple</small></label>
                <input type="text" placeholder="Email" class="form-control" required v-model="receipient_emails">
              </div>
              <div class="form-group" v-if="selected_receipient == 0">
                <label>Target Receipients</label>
                <select v-model="announcement.target" required class="form-control">
                  <option value="traders">Traders</option>
                  <option value="signals">Signal Subscribers</option>
                  <option value="academy">Academy Learners</option>
                  <option value="all">All</option>
                </select>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>Title</label>
            <input
              type="text"
              v-model="announcement.title"
              required
              placeholder="Title"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label>Content</label>
            <editor
              api-key="no-api-key"
              v-model="announcement.content"
              :init="{
                height: 500,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount',
                ],
                toolbar:
                  'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help',
              }"
            />
          </div>
          <div class="form-group">
            <button class="btn btn-primary">Send</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      announcement: {},
      selected_receipient: 0,
      receipient_emails: ''
    };
  },

  methods: {
    submit() {
      this.$loader.start()

      let data = this.announcement
      data.type = this.selected_receipient
      data.emails = this.receipient_emails

      this.$axios
        .post("/api/v1/admin/announcements", data)
        .then((response) => {
          this.$loader.stop()
          
          this.$router.push({
            name: "admin.announcements.show",
            params: { id: response.data.id },
          });
        });
    },
  },
};
</script>